const Subheading = ({ center, children }) => {
  return (
    <div
      className={`w-full flex flex-wrap ${
        center && "justify-center text-center"
      }`}
    >
      {children}
    </div>
  );
};

export default Subheading;
