const FormWrapper = ({ center, children }) => {
  return (
    <div
      className={`flex flex-col lg:w-[40vw] min-h-[700px] lg:min-h-[650px] h-fit justify-center ${
        center && "!translate-x-0"
      } w-[90%] lg:-translate-x-1/2 max-w-[600px]`}
    >
      {children}
    </div>
  );
};

export default FormWrapper;
