const Banner = () => {
  return (
    <svg
      width="403"
      height="669"
      viewBox="0 0 403 669"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_191_10642)">
        <path d="M402 268L268 402L402 402L402 268Z" fill="#C424A3" />
        <path d="M402 402L268 268L268 402L402 402Z" fill="#FFD668" />
      </g>
      <g clipPath="url(#clip1_191_10642)">
        <rect
          width="134"
          height="134"
          transform="translate(135 536) rotate(-90)"
          fill="#5038BC"
        />
        <rect
          x="202"
          y="536"
          width="134"
          height="67"
          transform="rotate(-90 202 536)"
          fill="#64E6FB"
        />
      </g>
      <rect
        width="134"
        height="134"
        transform="translate(135 134)"
        fill="#64E6FB"
      />
      <g clipPath="url(#clip2_191_10642)">
        <path d="M134 268L268 402L268 268L134 268Z" fill="#C424A3" />
        <path
          d="M268.279 402.279L134.279 268.279L134.279 402.279L268.279 402.279Z"
          fill="#5038BC"
        />
        <circle cx="201" cy="335" r="45" fill="#FFD668" />
      </g>
      <g clipPath="url(#clip3_191_10642)">
        <circle cx="402" cy="134" r="134" fill="#5038BC" />
      </g>
      <g clipPath="url(#clip4_191_10642)">
        <circle cx="403" cy="535" r="134" fill="#FFD668" />
      </g>
      <g clipPath="url(#clip5_191_10642)">
        <circle
          cx="269"
          cy="535"
          r="134"
          transform="rotate(180 269 535)"
          fill="#C424A3"
        />
      </g>
      <g clipPath="url(#clip6_191_10642)">
        <path d="M134 268L5.85733e-06 402L134 402L134 268Z" fill="#64E6FB" />
      </g>
      <circle cx="336" cy="201" r="49" stroke="#FFD668" strokeWidth="36" />
      <defs>
        <clipPath id="clip0_191_10642">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(402 402) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip1_191_10642">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(135 536) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip2_191_10642">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(134 268)"
          />
        </clipPath>
        <clipPath id="clip3_191_10642">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(268)"
          />
        </clipPath>
        <clipPath id="clip4_191_10642">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(269 401)"
          />
        </clipPath>
        <clipPath id="clip5_191_10642">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(403 669) rotate(180)"
          />
        </clipPath>
        <clipPath id="clip6_191_10642">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(134 268) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Banner;