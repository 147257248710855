import ViewPasswordLogo from "./components/ViewPasswordLogo";
import Link from "next/link";
import { useState } from "react";
import InputGroup from "./components/InputGroup";
import InputLabel from "./components/InputLabel";
import InputWrapper from "./components/InputWrapper";
import InputField from "./components/InputField";

const FormFieldsWrapper = ({ children }) => {
  return <div className="w-full mt-8 flex flex-col">{children}</div>;
};

const FormFields = ({ type }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <FormFieldsWrapper>
      <>
        {type === "register" && (
          <InputGroup>
            <InputLabel>Full Name</InputLabel>
            <InputWrapper>
              <InputField
                required={true}
                name="fullName"
                placeholder="Enter your full name"
                type="text"
              />
            </InputWrapper>
          </InputGroup>
        )}
        {type !== "reset" && (
          <InputGroup>
            <InputLabel>Email Address</InputLabel>
            <InputWrapper>
              <InputField
                required={true}
                name="email"
                placeholder="Enter your email address"
                type="email"
              />
            </InputWrapper>
          </InputGroup>
        )}
        {type !== "forgot" && (
          <InputGroup>
            <InputLabel>
              {type === "login" ? (
                <>
                  <div>Password</div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    <Link href={"/login/forgot"}>Forgot your password?</Link>
                  </div>
                </>
              ) : (
                <>Password</>
              )}
            </InputLabel>
            <InputWrapper>
              <InputField
                required={true}
                name="password"
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
              />
              <ViewPasswordLogo
                size={16}
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputWrapper>
          </InputGroup>
        )}
        {type === "reset" && (
          <InputGroup>
            <InputLabel>Confirm password</InputLabel>
            <InputWrapper>
              <InputField
                required={true}
                name="confirmPassword"
                placeholder="Enter your password"
                type={showConfirmPassword ? "text" : "password"}
              />
              <ViewPasswordLogo
                size={16}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </InputWrapper>
          </InputGroup>
        )}
      </>
    </FormFieldsWrapper>
  );
};

export default FormFields;
