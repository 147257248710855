const Heading = ({ center, children }) => {
  return (
    <div
      style={{ fontSize: "clamp(24px, 3vw, 30px)" }}
      className={`font-bold text-[#5038bc] mt-[0.25vw] ${
        center && "text-center"
      }`}
    >
      {children}
    </div>
  );
};

export default Heading;
