import Link from "next/link";
import Heading from "./components/Heading";
import Subheading from "./components/SubHeading";
import { useMixpanelClient } from "../../../hooks/useMixpanelClient";

const FormHeader = ({ title, tagline, altTagline, href, center, track }) => {
  return (
    <div>
      <Heading center={center}>{title}</Heading>
      <Subheading center={center}>
        <div className='font-normal mr-1'>{tagline}</div>
        {altTagline && (
          <div
            className='font-normal mr-1'
            onClick={() => {
              useMixpanelClient.track(track);
            }}
          >
            <span className='text-[#5039bc] font-bold'>
              <Link href={href}>{altTagline}</Link>
            </span>
          </div>
        )}
      </Subheading>
    </div>
  );
};

export default FormHeader;
