const InputField = ({ placeholder, required, name, type }) => {
  return (
    <input
      type={type}
      required={required}
      name={name}
      placeholder={placeholder}
      className={`border-transparent border-[2px] appearance-none rounded h-12 w-full py-4 px-2 font-normal text-[16px] shadow-sm focus:outline-[2px] focus:outline focus:outline-transparent focus:outline-offset-2 focus-border-transparent`}
    />
  );
};

export default InputField;
