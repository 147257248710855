const Container = ({ children }) => {
  return (
    <div className="absolute right-0 top-1/2 -translate-y-1/2 hidden lg:block">
      {children}
    </div>
  );
};

const BannerContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default BannerContainer;
